
import { createSlice } from '@reduxjs/toolkit';

// Helper function to get cart data from localStorage
const loadCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem('cart');
  return storedCart ? JSON.parse(storedCart) : [];
};

// Helper function to save cart data to localStorage
const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

export const bookSlice = createSlice({
  name: 'books',
  initialState: {
    bookData: [], // Initial state for book data
    cartData: loadCartFromLocalStorage(), // Load initial cart data from localStorage
  },
  reducers: {
    // Set book data in the store
    setBook: (state, action) => {
      state.bookData = action.payload;
    },

    // Add a book to the cart
    addToCart: (state, action) => {
      const book = action.payload;
      const existingItemIndex = state.cartData.findIndex((item) => item.id === book.id);

      if (existingItemIndex !== -1) {
        // If the book exists in the cart, increase its quantity
        state.cartData[existingItemIndex].quantity += 1;
      } else {
        // If the book doesn't exist, add it to the cart
        state.cartData.push({
          id: book.id,
          name: book.booknameinenglish,
          price: book.discountedprice,
          quantity: 1,
          image: book.image,
        });
      }

      // Save updated cart to localStorage
      saveCartToLocalStorage(state.cartData);
    },

    updateCartQuantity: (state, action) => {
      const { bookId, newQuantity } = action.payload;
      const existingItemIndex = state.cartData.findIndex((item) => item.id === bookId);

      if (existingItemIndex !== -1) {
        // Ensure the new quantity is not less than 0
        state.cartData[existingItemIndex].quantity = newQuantity >= 0 ? newQuantity : 0;

        // If the quantity becomes 0, remove the item from the cart
        if (state.cartData[existingItemIndex].quantity === 0) {
          state.cartData.splice(existingItemIndex, 1);
        }

        // Save updated cart to localStorage
        saveCartToLocalStorage(state.cartData);
      }
    },

    // Remove a book from the cart
    removeFromCarts: (state, action) => {
      const bookId = action.payload;

      // Remove the book by filtering it out
      state.cartData = state.cartData.filter((item) => item.id !== bookId);

      // Save updated cart to localStorage
      saveCartToLocalStorage(state.cartData);
    },

    // Clear all items from the cart
    clearCart: (state) => {
      state.cartData = [];
      // Save empty cart to localStorage
      saveCartToLocalStorage(state.cartData);
    }
  },
});

// Export actions
export const { setBook, addToCart, removeFromCarts, clearCart ,updateCartQuantity} = bookSlice.actions;

// Selectors to get book and cart data
export const selectBookData = (state) => state.books.bookData;
export const selectCartData = (state) => state.books.cartData;

// Export the reducer to be used in the store
export default bookSlice.reducer;

