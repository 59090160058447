import React from 'react';
import { Link } from 'react-router-dom';
import {selectCartData} from '../ReduxSlice/BookSlice';
import { useSelector } from 'react-redux';



const CartIcon = () => {
    const BookCart = useSelector(selectCartData);
    console.log('count',BookCart)
    const totalQuanity = BookCart.reduce((sum,item)=> sum+item.quantity,0)
    console.log("totalQuanity",totalQuanity)


    return (
        <Link to={`/cart`}>
            <button className="relative inline-flex items-center px-5 py-1.5 bg-white overflow-hidden text-lg font-medium text-black rounded-full hover:text-black group hover:bg-gray-50">
                {/* Background animation */}
                <span className="absolute left-0 block w-full h-0 transition-all  bg-yellow-400  opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

                {/* Cart icon with item count */}
                <span className="relative flex items-center justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className= {`w-6 h-6  ${BookCart.length > 0 ? 'mt-1.5' : ''}`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                        />
                    </svg>

                    {/* Count badge */}
                    {BookCart.length > 0 && (
                        <span className="absolute -top-0 -right-2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                            {totalQuanity}
                        </span>
                    )}
                </span>

                {/* Text for the cart */}
                {/* <span className="ml-2">Cart {count > 0 && `(${count})`}</span> */}
            </button>
        </Link>
    );
};

export default CartIcon;
