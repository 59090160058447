/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config/config';
import loadingSvg from '../assets/bounceLoad.svg';
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';
import { useSelector, useDispatch } from 'react-redux';
import { setBook, selectBookData,addToCart } from '../ReduxSlice/BookSlice';




function FeaturedBooks() {
    const { API_BASE_URL } = config;

    const dispatch = useDispatch();

    

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');



    const [bookData, setBookData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAnimationText, setShowAnimationText] = useState({});

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                });

                const data = await response.json();
                console.log("dataForAll",data)
                dispatch(setBook(data));

                const featuredBooks = data.filter(book => book.isfeatured === 1).map(book=>{
                    const updatePdfLink = book.pdflink.includes("drive.google.com")
                   // ? book.pdflink : `${API_BASE_URL}${book.pdflink}`
                    ? book.pdflink : book.pdflink
    
                    return {...book, pdflink:updatePdfLink}
                    });
                console.log("featuredBooks",featuredBooks);
                setBookData(featuredBooks);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [API_BASE_URL,dispatch]);



    const handleViewPdfClick  = (bookpdflink)=>{
        setPdfUrl(bookpdflink);
        setShowModal(true);


        // console.log("saurbah navigation above if")

        // sessionStorage.setItem('pdflink',bookpdflink)

        if(bookpdflink.includes('drive.google.com')){
            // console.log("saurbah navigation inside if")

            console.log("Pdflink",bookpdflink);
            console.log(bookpdflink.includes('drive.google.com'));
            return  window.open(bookpdflink, '_blank');
      
          }
          else{
            // console.log("saurbah navigation else")

            //  return navigate('books/flip');


          }


    }
    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">
            <img className='h-36 w-36' src={loadingSvg} alt="Loading..." />
        </div>
    }

    if (error) {
        return <p>{error}</p>;
    }

    // const totalPages = Math.ceil(bookData.length / itemsPerPage);

        const handleAddToCart = (book) => {
            // const storedCart = localStorage.getItem('cart');
            // const cart = storedCart ? JSON.parse(storedCart) : [];

            // const existingItemIndex = cart.findIndex((item) => item.id === book.id);

            // if (existingItemIndex !== -1) {
            //     cart[existingItemIndex].quantity += 1;
            // } else {
            //     cart.push({
            //         id: book.id,
            //         name: book.booknameinenglish,
            //         price: book.discountedprice,
            //         quantity: 1,
            //         image: book.image,
            //     });
            // }
            dispatch(addToCart(book));

            setShowAnimationText((prev) => ({
                ...prev,
                [book.id]: true,
            }));

            setTimeout(() => {
                setShowAnimationText((prev) => ({
                    ...prev,
                    [book.id]: false,
                }));
            }, 1000);

            // localStorage.setItem('cart', JSON.stringify(cart));
        };
      

    // const scrollDownToSection = () => {
    //     const section = document.getElementById("booklist");
    //     section?.scrollIntoView({ behavior: "smooth" });
    // };
    const BookDetails = (book) =>{
     //   event.stopPropagation()
        console.log("saurabh",book)
        navigate(`/book/${book.id}`)

    }

    return (
        <section className='mt-8 mb-8 bg-white'>
            <div className='max-w-7xl mx-auto py-5'>
                <div className='md:flex lg:flex xl:flex flex-none gap-2'>
                    <p className='text-lg font-semibold bg-yellow-400 rounded-lg py-2 px-5 w-fit mb-2 md:ml-0 lg:ml-0 xl:ml-0 ml-3'><h2>Featured Books</h2></p>
                </div>
                <hr className='border-b-2 border-gray-200 mt-2' />

            </div>
            <div className="text-left md:flex-row max-w-full md:px-16 lg:px-16 justify-center xl:px-20 px-[2px] mx-auto md:mt-5 mt-10 items-center z-0">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 md:px-5 lg:px-12 xl:px-12 px-4 gap-8 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-2 pb-2 md:gap-y-8 lg:gap-y-8 xl:gap-y-8 gap-y-5">
                    {bookData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((book) => (
                        <div key={book.id}   className="relative flex flex-col text-gray-800 bg-white md:w-64 lg:w-72 xl:w-72 w-full border border-gray-100 p-1 rounded-md space-y-3 hover:scale-105 hover:transition hover:ease-out cursor-pointer shadow-lg shadow-gray-200 hover:bg-blue-50 duration-150">
                            <div  onClick={() => BookDetails(book)} className="relative h-full overflow-hidden shadow-md bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40 flex items-center mx-auto">
                                <img
                                    src={book.image}
                                    alt={book.booknameinenglish}
                                    layout="fill"
                                    objectFit="cover"
                                    className="bg-blue-200 h-54 mx-auto"
                                />
                                <div className="absolute inset-0 flex items-start mt-3 text-white">
                                    <p className="text-lg w-fit bg-red-600 font-semibold px-2 rounded-tr-md rounded-br-md flex gap-1 items-center">Featured <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-yellow-400">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                                    </svg>
                                    </p>
                                </div>
                            </div>


                            <div  onClick={() => BookDetails(book)} className='flex items-center justify-between px-1 cursor-pointer'>
                                <h5 className="block font-sans md:text-xl lg:text-xl xl:text-xl text-xl font-bold leading-snug tracking-normal text-blue-gray-900 ">
                                    {book.booknameinenglish}
                                </h5>
                            </div>

                            <Link to={`/book/${book.id}`}       onClick={(event) => event.stopPropagation()} className='items-center flex text-xs gap-1 px-2 underline underline-offset-2 hover:bg-yellow-300 hover:rounded-full hover:px-3 hover:transition hover:delay-200 hover:py-1 w-fit'>
                                Details
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </Link>

                        
                             <div  onClick={ ()=>handleViewPdfClick(book.pdflink)}  rel="noopener noreferrer" className='px-3 font-bold text-gray-600 py-0.5 bg-yellow-400 w-fit rounded-md text-xs'>
                                View PDF - Free
                            </div>

                            <p   onClick={() => BookDetails(book)} className="block text-sm px-2 font-normal leading-5">
                                {book.description}
                            </p>

                            <div class="flex items-center justify-between px-2 pb-1">
                                <p  onClick={() => BookDetails(book)} > <span className='text-xl font-semibold text-slate-900'>Rs.</span>
                                    <span class="text-sm text-slate-900 line-through">{book.price}</span>
                                    <span class="text-2xl font-bold text-slate-900">{book.discountedprice}{' '}</span>
                                </p>
                                <div class="flex items-center">
                                    <div class="relative py-2">
                                        <div class="t-0 absolute left-16 -mt-2 rounded-full">

                                            {showAnimationText[book.id] && (
                                                <p className='flex h-2 w-2  animate-ping items-center justify-center rounded-full bg-blue-700 p-3 text-xs text-white'>
                                                    +1
                                                </p>
                                            )}
                                        </div>
                                        <button onClick={() => handleAddToCart(book)} class="flex items-center rounded-full bg-[#e4002b] px-3 py-1 text-center text-sm font-medium text-gray-200 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-blue-300" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2 h-4 w-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                            </svg>
                                            Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="flex justify-center mt-8">
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
                        scrollDownToSection();
                    }}
                    disabled={currentPage === 1}
                    className="px-3 py-2 mr-2 font-semibold text-gray-700 bg-gray-200 rounded"
                >
                    Previous
                </button>
                <span className="px-3 py-2 font-semibold text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => prevPage + 1);
                        scrollDownToSection();
                    }}
                    disabled={currentPage * itemsPerPage >= bookData.length}
                    className="px-3 py-2 ml-2 font-semibold text-white bg-blue-500 rounded"
                >
                    Next
                </button>
            </div> */}
                        {showModal &&  pdfUrl &&  <PdfModal value={showModal} pdf_link={pdfUrl}  onClose={handleCloseModal}/>}

        </section>
    );
}

export default FeaturedBooks;
