import React, { useEffect, useState } from 'react';
import empty from "../assets/images/empty.svg";
import { useNavigate } from 'react-router-dom';
import { removeFromCarts, selectCartData, updateCartQuantity } from '../ReduxSlice/BookSlice';
import { useDispatch, useSelector } from 'react-redux';

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartData);  // Use Redux state instead of local state
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const navigate = useNavigate();

  // Calculate subtotal based on cart items
  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // Calculate shipping charges
  const calculateShippingCharges = (subtotalValue) => {
    if (subtotalValue >= 2000) {
      return 0;
    } else {
      return subtotalValue * 0.2;
    }
  };

  // Calculate total cost including shipping
  const calculateTotal = () => {
    const subtotalValue = calculateSubtotal();
    if (subtotalValue >= 2000) {
      return subtotalValue;
    } else {
      return subtotalValue + calculateShippingCharges(subtotalValue);
    }
  };

  // Whenever the cart items change, recalculate subtotal and total
  useEffect(() => {
    const subtotalValue = calculateSubtotal();
    setSubtotal(subtotalValue);
    const shippingCharges = calculateShippingCharges(subtotalValue);
    setDeliveryCharge(shippingCharges);
    setTotal(subtotalValue + shippingCharges);
  }, [cartItems]);  // Dependency on Redux cartItems

  // Handle removing an item from the cart via Redux action
  const removeFromCart = (bookId) => {
    dispatch(removeFromCarts(bookId));
  };

  // Proceed to checkout
  const handleCheckout = () => {
    const checkoutData = {
      cartItems: cartItems.map(item => ({
        bookId: item.id,
        bookName: item.name,
        bookQuantity: item.quantity,
        perUnitBookPrice: item.price,
        totalBookPrice: item.price * item.quantity,
        addDate: new Date().toISOString(),
        updateDate: new Date().toISOString(),
      })),
      total: calculateTotal(),
      delivery: deliveryCharge
    };

    // console.log('Checkout Data:', checkoutData);
    navigate('/books/checkout', { state: checkoutData });
  };

  const updateQuantity = (bookId, newQuantity) => {
    // setCartItems(prevCartItems => {
    //   const updatedCart = prevCartItems.map(item =>
    //     item.id === bookId ? { ...item, quantity: newQuantity >= 0 ? newQuantity : 0 } : item
    //   );

    //   localStorage.setItem('cart', JSON.stringify(updatedCart));
    //   return updatedCart;
    // });
    dispatch(updateCartQuantity({ bookId, newQuantity }));

  };
  const handleContinueShoping = ()=> {
    navigate('/')
  }

  return (
    <div>
      <section className="h-auto py-12 sm:py-16 lg:py-20">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">

          {total < 200 && (
            <div className="flex items-center justify-center py-3 bg-yellow-400 max-w-3xl mx-auto rounded-md pl-2 pr-2">
              <h2 className="md:text-lg lg:text-lg xl:text-lg text-sm font-semibold text-gray-900 md:tracking-[2px] tracking-[0px]">You don't have enough items in your cart!</h2>
            </div>
          )}

          {total >= 200 && subtotal < 2000 && (
            <div className="flex items-center justify-center py-3 bg-yellow-400 max-w-3xl mx-auto rounded-md pl-2 pr-2">
              <h2 className="md:text-lg lg:text-lg xl:text-lg text-sm font-semibold text-gray-900 md:tracking-[2px] tracking-[0px]">Add more items to cart to get free delivery</h2>
            </div>
          )}

          {subtotal >= 2000 && (
            <div className="flex items-center justify-center py-3 bg-yellow-400 max-w-3xl mx-auto rounded-md pl-2 pr-2">
              <h2 className="md:text-lg lg:text-lg xl:text-lg text-sm font-semibold text-gray-900 md:tracking-[2px] tracking-[0px]">Congratulations! No Delivery Charges.</h2>
            </div>
          )}

          <div className="flex items-center justify-center mt-4">
            <h1 className="text-2xl font-semibold text-gray-900">Your Cart</h1>
          </div>

          {cartItems.length === 0 ? (
            <div className='mt-5 justify-center mx-auto border border-gray-200 max-w-2xl p-10 items-center text-center rounded-lg space-y-4'>
              <img className='h-40 justify-center mx-auto' src={empty} alt="empty-cart" />
              <p>Your cart is empty.</p>
              <button onClick={() => navigate('/')} className='w-full px-4 py-2 font-semibold text-white bg-yellow-500 rounded-lg'>
              Continue Shopping
              </button>
            </div>
          ) : (
            <div className="mx-auto mt-8 max-w-4xl md:mt-12">
              <div className="bg-white shadow">
                <div className="px-4 py-6 sm:px-8 sm:py-10">
                  <div className="flow-root">
                    <ul className="-my-8">
                      {cartItems.map((item, index) => (
                        <li key={index} className="flex flex-col space-y-3 py-6 text-left sm:flex-row sm:space-x-5 sm:space-y-0">
                          <div className="shrink-0">
                            <img className='w-20 h-20 object-cover mr-4' src={item.image} alt={item.name} />
                          </div>

                          <div className="relative flex flex-1 flex-col justify-between">
                            <div className="sm:col-gap-5 sm:grid sm:grid-cols-2">
                              <div className="pr-8 sm:pr-5">
                                <p className="text-base font-semibold text-gray-900">{item.name}</p>
                              </div>

                              <div className="mt-4 flex items-end justify-between sm:mt-0 sm:items-start sm:justify-end">
                                <p className="shrink-0 w-20 text-base font-semibold text-gray-900 sm:order-2 sm:ml-8 sm:text-right">Rs.{item.price * item.quantity}</p>
                                <div className="sm:order-1">
                                  <div className="mx-auto flex h-8 items-stretch text-gray-600">
                                    <button onClick={() => updateQuantity(item.id, item.quantity - 1)} className="flex items-center justify-center rounded-l-md bg-gray-200 px-4 transition hover:bg-black hover:text-white">-</button>
                                    <div className="flex w-full items-center justify-center bg-gray-100 px-4 text-xs uppercase transition">{item.quantity}</div>
                                    <button onClick={() => updateQuantity(item.id, item.quantity + 1)} className="flex items-center justify-center rounded-r-md bg-gray-200 px-4 transition hover:bg-black hover:text-white">+</button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="absolute top-0 right-0 flex sm:bottom-0 sm:top-auto">
                              <button onClick={() => removeFromCart(item.id)} type="button" className="flex rounded p-2 text-center text-gray-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900">
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" className=""></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-6 border-t border-b py-2">
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-gray-400">Subtotal</p>
                      <p className="text-sm font-medium text-gray-900"><span className="text-sm font-normal text-gray-400">Rs.</span>{subtotal}.00</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-gray-400">Shipping</p>
                      <p className="text-sm font-medium text-gray-900"><span className="text-sm font-normal text-gray-400">Rs.</span>{deliveryCharge}.00</p>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Total</p>
                    <p className="text-2xl font-medium text-gray-900"><span className="text-sm font-normal text-gray-400">Rs.</span>{total}.00</p>
                  </div>
                  {/* 
                  <div className="mt-6 text-center flex flex-row gap-32">
                    <button onClick={handleCheckout} className="group inline-flex w-full items-center justify-center rounded-md bg-yellow-400 px-4 py-3 text-base font-semibold text-white transition-all duration-200 ease-in-out focus:bg-yellow-500">Continue Shopping</button>
                    <button onClick={handleCheckout} className="group inline-flex w-full items-center justify-center rounded-md bg-yellow-400 px-4 py-3 text-base font-semibold text-white transition-all duration-200 ease-in-out focus:bg-yellow-500">Proceed to Checkout</button>

                  </div> */}

                  <div className="mt-6 text-center flex flex-row justify-center gap-8">
                    <button
                      onClick={handleContinueShoping}
                      className="group inline-flex items-center justify-center rounded-md bg-[#861d34] px-6 py-3 text-base font-semibold text-white shadow-md transition-all duration-300 ease-in-out hover:bg-[#621c2b] hover:shadow-lg focus:ring-2 focus:ring-blue-300"
                    >
                      Continue Shopping
                    </button>

                    <button
                      onClick={handleCheckout}
                      className="group inline-flex items-center justify-center rounded-md bg-yellow-400 px-6 py-3 text-base font-semibold text-white shadow-md transition-all duration-300 ease-in-out hover:bg-yellow-500 hover:shadow-lg focus:ring-2 focus:ring-green-300"
                    >
                      Proceed to Checkout
                    </button>
                  </div>

                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Cart;

